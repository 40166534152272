/* Footer.module.css */
.footer {
    background-color: #333;
    color: #fff;
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.logoWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 32px
}

.logo {
    color: #fff;
    width: 150px;
    height: 150px;
    border-radius: 75px;
}

.hubLogo {
    width: 150px;
    height: 200px;
}

.appInfo a:nth-child(1) {
    color: #4848d5;
    text-decoration: none;
    transition: color 0.15s ease;
}

.appInfo a:nth-child(1):hover {
    color: #FF6525;
    text-decoration: none;
}

.appInfo a:nth-child(2) {
    color: #FF6525;
    text-decoration: none;
    transition: color 0.15s ease;
}

.appInfo a:nth-child(2):hover {
    color: #4848d5;
    text-decoration: none;
}

.contact,
.appInfo {
    text-align: left;
    font-family: "Poppins";
}

.contact p,
.appInfo p {
    margin: 5px 0;
}

@media (max-width: 770px) {
    .logoWrapper {
        flex-direction: column;
    }

    .footer {
        flex-direction: column;
        gap: 32px
    }
}