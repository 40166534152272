.container {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #212121;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
}

.title {
    color: #FFF;
    font-family: "Poppins";
}

@media (max-width: 1400px) {
    .form {
        width: 50%;
    }
}

@media (max-width: 992px) {
    .form {
        width: 70%;
    }
}