.inputGroup {
    position: relative;
    margin: 15px 0;
    width: 100%;
}

.input {
    border: solid 1.5px #9e9e9e;
    border-radius: 1rem;
    background: none;
    padding: 1rem;
    font-size: 1rem;
    color: #f5f5f5;
    transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
    width: 100%;
}

.label {
    position: absolute;
    left: 15px;
    color: #e8e8e8;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.dirty {
    position: absolute;
    left: 15px;
    pointer-events: none;
    transform: translateY(1rem);
    /* transition: 150ms cubic-bezier(0.4, 0, 0.2, 1); */
}


.input:focus~.dirty,
.input:user-invalid~.dirty {
    transform: translateY(-50%) scale(0.8);
    background: #212121;
    padding: 0 .2em;
    color: red;
}

.input:focus,
.input:user-invalid {
    outline: none;
    border: 1.5px solid red;
}

.input:focus,
.input:valid {
    outline: none;
    border: 1.5px solid #FF6525;
}

.input:focus~label,
.input:valid~label {
    transform: translateY(-50%) scale(0.8);
    background-color: #212121;
    padding: 0 .2em;
    color: #FF6525;
}