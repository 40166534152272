.btn {
    margin: 1rem;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    font-size: 1.1em;
    text-transform: uppercase;
    padding: 0.6em 1.2em;
    background-color: #FF6525;
    color: #FFF;
    box-shadow: 0 8px 24px 0 rgb(255 235 167 / 20%);
    transition: all .3s ease-in-out;
}

.btn:hover {
    background-color: #FFF;
    color: #FF6525;
    box-shadow: 0 8px 24px 0 rgb(16 39 112 / 20%);
    cursor: pointer;
}