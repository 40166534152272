@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.navbar {
    display: flex;
    position: absolute;
    top: 10;
    left: 0;
    right: 0;
    z-index: 100;
    flex-direction: row;
    background-color: rgba(0, 0, 0, 0.1);
    /* flex: 1; */
    /* height: 60px; */
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;
}

.navbarOptions {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-left: 50px;
}

.logo {
    color: #fff;
    width: 80px;
    height: 80px;
    border-radius: 40px;
}

.option {
    color: #fff;
    text-decoration: none;
    font-family: "Poppins";
    font-size: 1.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.active {
    color: #FF6525;
    text-decoration: none;
    font-family: "Poppins";
    font-size: 1.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

nav a {
    text-decoration: none;
    font-weight: bold;
    position: relative;
    transition: color 0.3s;
}

nav a:hover {
    color: #FF6525;
    cursor: pointer;
}

nav a::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #FF6525;
    transform-origin: center;
    transition: transform 0.15s ease-out;
}

nav a:hover::after {
    transform: scaleX(1);
    transform-origin: center;
}

button {
    text-decoration: none;
    font-weight: bold;
    position: relative;
    transition: color 0.3s;
    border: none;
    background-color: transparent;
}

.option:hover {
    color: #FF6525;
    cursor: pointer;
}

.option::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #FF6525;
    transform-origin: center;
    transition: transform 0.15s ease-out;
}

.option:hover::after {
    transform: scaleX(1);
    transform-origin: center;
}

.hamburger {
    display: none;
}

.mobileNavbar {
    display: none;
}

@media (max-width: 767px) {
    .hamburger {
        display: flex;
        position: absolute;
        right: 0;
        padding-top: 10px;
        margin-right: 20px;
        z-index: 10;
    }

    .navbarOptions {
        display: none;
    }

    .mobileNavbar {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 50;
    }

    .option {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .active {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}