.button {
    position: relative;
    width: 200px;
    height: 55px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px solid #4848d5;
    background-color: #4848d5;
    border-radius: 10px;
}

.button,
.button__icon,
.button__text {
    transition: all 0.3s;
}

.button .button__text {
    transform: translateX(30px);
    color: #fff;
    font-weight: 600;
}

.button .button__icon {
    position: absolute;
    transform: translateX(159px);
    height: 100%;
    width: 39px;
    background-color: #4848d5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.button .svg {
    width: 30px;
    stroke: #fff;
}

.button:hover {
    background: #4848d5;
}

.button:hover .button__text {
    color: transparent;
}

.button:hover .button__icon {
    width: 198px;
    transform: translateX(0);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.button:active .button__icon {
    background-color: #4848d5;
}

.button:active {
    border: 1px solid #4848d5;
}