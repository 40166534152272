.container {
    padding-top: 32px;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}

.leftSide {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    flex-wrap: wrap;
}

.fullScreenButton {
    display: block;
}

.calendar {
    padding: 32px 0px;
}

.calendarFull {
    padding: 0 16px;
}

@media(max-width: 770px) {
    .fullScreenButton {
        display: none;
    }
}

@media (max-width: 900px) {
    .header {
        /* flex-direction: column; */
        flex-wrap: wrap;
    }
}