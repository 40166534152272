* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.showcaseWrapper {
    height: 100vh;
    width: 100vw;
    position: relative;
    overflow: hidden;
}

.showcaseContent {
    height: 100%;
    width: 100%;
    display: flex;
    transition: all 0.5s ease;
}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
}

.showcase {
    min-width: 100%;
    color: #fff;
    display: grid;
    place-content: center;
    place-items: center;
    text-align: center;
}

.showcase1 {
    min-width: 100%;
    color: #fff;
    display: grid;
    place-content: center;
    place-items: center;
    text-align: center;
    background: url("../../assets/landing/landing1.webp") center/cover no-repeat;
    transform: all 0.5s ease;
    transition: all 0.5s ease;
}

.showcase2 {
    min-width: 100%;
    color: #fff;
    display: grid;
    place-content: center;
    place-items: center;
    text-align: center;
    background: url("../../assets/landing/landing4.webp") center/cover no-repeat;
    transform: all 0.5s ease;
    transition: all 0.5s ease;
}

.showcase3 {
    min-width: 100%;
    color: #fff;
    display: grid;
    place-content: center;
    place-items: center;
    text-align: center;
    background: url("../../assets/landing/landing3.webp") center/cover no-repeat;
    transform: all 0.5s ease;
    transition: all 0.5s ease;
}

.headingText {
    font-size: 1.6rem;
    font-family: "Poppins";
    width: 90%;
    margin: 1.5rem auto;
    z-index: 49;
    text-shadow: 2px 2px 3px #000;
}

.showcase button {
    background: transparent;
    border: 2.5px solid #FFF;
    color: #FFF;
    text-shadow: 2px 2px 3px #000;
    font-weight: bold;
    font-family: inherit;
    text-transform: uppercase;
    padding: 0.8rem 1.4rem;
    outline: 0;
    cursor: pointer;
    transform: all 0.5s ease;
    z-index: 49;
}

.showcase button:hover {
    background: #fff;
    color: #FF6525;
    font-weight: bold;
    text-shadow: 0 0 0;
}

.dots {
    position: absolute;
    bottom: 2rem;
    display: flex;
    justify-content: center;
    width: 100%;
}

.dots span {
    margin: 0 0.5rem;
    color: #fff;
    font-size: 0.8rem;
    opacity: 0.9;
    cursor: pointer;
    transition: opacity 0.5s ease;
}

.dots span:hover {
    opacity: 0.5;
}

.leftArrow {
    top: 50%;
    position: absolute;
    display: flex;
    left: 0;
    align-items: center;
    padding: 0 10px;
    z-index: 49;
}

.leftArrow span {
    position: absolute;
    margin: 0 0.5rem;
    color: #fff;
    font-size: 3rem;
    opacity: 0.9;
    cursor: pointer;
    transition: opacity 0.5s ease;
}

.leftArrow span:hover {
    opacity: 0.5;
}

.rightArrow {
    top: 50%;
    position: absolute;
    display: flex;
    right: 40px;
    align-items: center;
    padding: 0 10px;
    z-index: 49;
}

.rightArrow span {
    position: absolute;
    margin: 0 0.5rem;
    color: #fff;
    font-size: 3rem;
    opacity: 0.9;
    cursor: pointer;
    transition: opacity 0.5s ease;
}

.rightArrow span:hover {
    opacity: 0.5;
}

@media screen and (min-width: 776px) {
    .showcase h1 {
        font-size: 3rem;
    }
}

@media screen and (min-width: 992px) {
    .showcase h1 {
        font-size: 4rem;
    }

    .showcase button {
        font-size: 1.05rem;
    }
}