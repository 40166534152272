.container {
    padding-top: 120px;
    margin: 0 20px;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.leftSide {
    display: flex;
    flex-direction: column;
}

.rightSide {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.scrollBox {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
}

.scrollBox::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.modalContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 40%; */
    align-items: center;
    overflow-y: auto;
}

.form {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
}

.datepicker {
    color: #FFF;
}

.timeslotWrapper {
    display: flex;
    flex-direction: row;
    gap: 32px
}

.headingContainer {
    font-size: 0.8rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.closeIcon:hover {
    cursor: pointer;
    opacity: 0.8;
}

@media (max-width: 900px) {
    .header {
        flex-wrap: wrap;
    }

    .rightSide {
        padding-top: 20px;
        flex-wrap: wrap;
    }

    .modal {
        height: 100%;
        /* justify-content: center; */
        /* align-items: center; */
    }
}

@media (max-width: 700px) {
    .header {
        flex-direction: column;
    }
}

@media (max-width: 470px) {
    .rightSide {
        padding-top: 40px;
        flex-direction: column;
    }
}