.button {
    background-color: #FFF;
    width: 125px;
    padding: 10px 25px;
    border-radius: 50px;
    border: 1px solid gray;
}

.button:hover{
    background-color: #FF6525;
    opacity: 0.5;
    color: #FFF;
    border: 0;
    cursor: pointer;
}

.selected {
    background-color: #FF6525;
    color: #FFF;
    border: 0;
}