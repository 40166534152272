/* GridView.css */
.gridView {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    border: 1px solid #ddd;
    padding: 20px 8px;
    text-align: center;
}

th {
    background-color: #f2f2f2;
    padding: 8px;
}

.fullscreen {
    border: 1px solid #ddd;
    padding: 0;
    text-align: center;
}


.multipleRows {
    background-color: #FF6525;
    /* Set the background color to red for multiple rows */
    padding: 5px;
    color: white;
    /* border-radius: 25px; */
    margin-bottom: 2px;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.multipleRows:hover {
    opacity: 0.7;
    cursor: pointer;
}

.singleRow {
    /* Define styles for a single row */
    padding: 5px;
    /* Add padding for better visual separation */
    color: black;
    /* Set text color to black */
    border-radius: 4px;
    /* Add border radius for rounded corners */
    margin-bottom: 2px;
    /* Add a small margin between rows */
}

.modalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    font-family: "Poppins";
}

.headingContainer {
    font-size: 0.8rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;
    color: #212121;
}

.commentWrapper {
    display: flex;
    flex-wrap: wrap;
}

.timeslotWrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-around;
    align-items: center;
    color: #212121;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    position: relative;
}

.deleteButton {
    position: absolute;
    top: 0;
    right: 0;
    width: 70px;
    height: 70px;
    border-radius: 24px;
    background-color: transparent;
}

.deleteButton:hover {
    background-color: transparent;
    opacity: 0.1;
    cursor: pointer;
}

.mobileView {
    width: 100%;
}

.mobileTimeslots {
    display: flex;
    flex-direction: column;
}

.icon:hover {
    opacity: 0.6;
    cursor: pointer;
}

@media (max-width: 768px) {
    .gridView {
        display: none;
    }
}

@media (min-width: 769px) {
    .mobileView {
        display: none;
    }
}